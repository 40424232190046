.btn {
  --scale: 2.5;
  --rotate-left: -360deg;
  --rotate-right: 360deg;

  :global(svg) {
    overflow: visible;

    path {
      transition: all 0.2s ease-out;
      transform-origin: center;
    }

    path:nth-child(5) {
      transform-origin: center;
    }

    path:nth-child(1) {
      transform-origin: 0 0;
    }

    path:nth-child(2) {
      transform-origin: 0 50%;
    }

    path:nth-child(3) {
      transform-origin: 0 100%;
    }

    path:nth-child(4) {
      transform-origin: 50% 0;
    }

    path:nth-child(6) {
      transform-origin: 50% 100%;
    }

    path:nth-child(7) {
      transform-origin: 100% 0;
    }

    path:nth-child(8) {
      transform-origin: 100% 50%;
    }

    path:nth-child(9) {
      transform-origin: 100% 100%;
    }
  }

  &:hover {
    /*:global(svg) {*/

    path:nth-child(5) {
      transform: scale(var(--scale)) rotate(var(--rotate-right));
      transition-delay: 0s;
      /*color: hsl(0, 100%, 50%);*/
    }

    path:nth-child(1) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.02s;
      /*color: hsl(40, 100%, 50%);*/
      transform-origin: 2px 2px;
    }

    path:nth-child(4) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.04s;
      /*color: hsl(80, 100%, 50%);*/
      transform-origin: 10px 2px;
    }
    path:nth-child(7) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.06s;
      /*color: hsl(120, 100%, 50%);*/
      transform-origin: 18px 2px;
    }

    path:nth-child(8) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.08s;
      /*color: hsl(160, 100%, 50%);*/
      transform-origin: 18px 10px;
    }

    path:nth-child(9) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.1s;
      /*color: hsl(200, 100%, 50%);*/
      transform-origin: 18px 18px;
    }

    path:nth-child(6) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.12s;
      /*color: hsl(240, 100%, 50%);*/
      transform-origin: 10px 18px;
    }

    path:nth-child(3) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.14s;
      /*color: hsl(280, 100%, 50%);*/
      transform-origin: 2px 18px;
    }

    path:nth-child(2) {
      transform: scale(var(--scale)) rotate(var(--rotate-left));
      transition-delay: 0.16s;
      /*color: hsl(320, 100%, 50%);*/
      transform-origin: 2px 10px;
    }
  }
}
