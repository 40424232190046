.navigation {
  @apply flex items-center relative;
  /*@apply pr-6;
  height: 4rem;*/

  /*&::before {
    @apply absolute;
    content: "";
    z-index: -1;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 4em;
    height: 4rem;
    transform: translate3d(-4rem, 0, 0) scaleY(-1);
    background-color: theme("colors.black");
    clip-path: url(#letter-path-l-horizontal);
    !*clip-path: polygon(0 70%, 20% 0, 100% 0, 100% 100%, 0 100%);*!
  }*/

  a {
    @apply relative ml-8 py-2 overflow-hidden text-base;
    @apply uppercase no-underline tracking-wider box-border text-white;

    @apply hidden;
    @screen lg {
      @apply flex;
    }

    &:first-child {
      margin-left: 2rem;
    }
    &:last-child {
      margin-right: 2rem;
    }

    &:not(.highlight) {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 100%;
        height: 2px;
        background: theme("colors.white");
        transform: translate3d(-105%, 0, 0);
        transition: all 0.2s;
      }

      &:hover,
      &:active {
        &:after {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    &.highlight {
      @apply border-white border rounded-lg px-3 bg-vollyellow-700 text-black border-black;

      height: 2rem;
      line-height: calc(1rem - 2px);
      transition: 0.15s ease-out;
      position: relative;
      z-index: 0;

      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        background: theme("colors.white");
        transform: translate3d(0, 100%, 0);
        transition: all 0.3s;
      }

      &:hover,
      &:active {
        border-color: theme("colors.white");
        color: theme("colors.black");

        &:after {
          transform: translate3d(0, 0, 0) skewY(15deg) scale(1, 3);
        }
      }
    }
  }

  div {
    @apply inline-block p-1 cursor-pointer;
  }
}
