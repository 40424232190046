.footer {
  --bottom-margin: 4rem;

  @apply bg-black text-white py-8;

  button {
    text-decoration-skip-ink: none;
    text-underline-offset: 2px;

    &:hover,
    &:focus {
      @apply no-underline;
    }
  }
}

.buttonText {
  display: contents;
}

.inner {
  @apply container;
}

.topLinks,
.bottomLinks,
.contact {
  @apply mb-16;
}

.topLinks {
  grid-area: toplinks;
  columns: 240px auto;

  li {
    @apply mb-4;
    list-style-type: none;

    &:last-child {
      @apply mb-0;
    }
  }
}

.contact {
  grid-area: contact;

  a {
    @apply text-white border;
  }
}

a.contactButton {
  @apply bg-vollyellow-700 text-black border-black;
}

.logos {
  grid-area: logos;

  svg {
    @apply fill-current;
  }
}

.bottomLinks {
  @apply self-end;
  grid-area: bottomlinks;
}

.navs {
  @apply flex justify-between mb-4 flex-wrap;

  nav {
    @apply flex items-center mb-8;

    a {
      @apply mr-8;

      &:last-child {
        @apply mr-0;
      }
    }
  }
}

.legal {
  flex-wrap: wrap;
}

@screen md {
  .inner {
    @apply gap-24;

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "toplinks toplinks toplinks"
      "contact contact contact"
      "bottomlinks bottomlinks logos";
  }

  .topLinks,
  .bottomLinks,
  .contact {
    @apply mb-0;
  }

  .bottomLinks {
    @apply mb-0;
  }

  .navs {
    @apply mb-14;
  }
}

@screen lg {
  .footer {
    @apply py-24;
  }

  .inner {
    @apply gap-36;

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 2.5fr 1fr 1fr;
    grid-template-areas:
      "toplinks toplinks contact"
      "bottomlinks  bottomlinks logos";
  }

  .topLinks {
    columns: 300px auto;
  }
}