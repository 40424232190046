.nav {
  font-family: "Moderat Extended", system-ui, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif;

  li {
    @apply mb-5;
  }

  a {
    @apply text-6xl no-underline text-white hover:text-gray-400;
    @apply relative block whitespace-nowrap transition-colors;

    &.active {
      @apply text-white text-opacity-100 hover:text-opacity-90;
    }

    &:after {
      content: "";
      position: absolute;
      width: 40%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      top: 0;
      margin-left: -40%;
      background: url("/permutation_blue.svg") repeat 0 0;
      background-size: 3rem;
      transition: all 0.15s ease-out;
    }

    &:hover {
      &:after {
        opacity: 1;
        margin-left: -20%;
        animation: move 25s infinite linear;
      }
    }
  }
}

@keyframes move {
  100% {
    animation-timing-function: linear;
    background-position: 1000% 0;
  }
}

@screen lg {
  .nav {
    ul {
      @apply flex flex-wrap;
    }

    li {
      @apply mb-14;

      flex: 1 1 50%;
      padding-right: 2rem;
    }
  }
}
