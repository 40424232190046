.menu {
  @apply block absolute inset-0 bg-black z-50;

  ul {
    @apply pl-0;
    list-style-type: none;
  }
  li {
    @apply pl-0;
  }
}

.back {
  @apply hidden;
}

.front {
  @apply bg-black text-white;
}

.header {
  @apply flex justify-between items-start;
  @apply bg-black top-0 z-10 mb-12;

  @screen 3xl {
    @apply px-14;
  }

  div {
    @apply inline-block py-1 cursor-pointer;
  }
}

.open {
  @apply sticky;
}

.logo {
  @apply self-baseline mt-1 ml-4;

  svg {
    width: auto;
    height: 2.5rem;
  }
}

.close {
  @apply bg-transparent border-0 p-3;
}

.inner {
  @apply container overflow-auto;
}

.left {
  @apply mb-14;
}

.right {
}

.secondaries {
  @apply flex mb-14;

  & > * {
    @apply w-1/2 pt-2;
  }
}

.ctas {
  @apply w-1/2;

  p {
    @apply text-white;
  }
}

@screen lg {
  .menu {
    @apply fixed inset-0 z-50 hidden text-white bg-transparent;
  }

  .back {
    @apply bg-gray-700 bg-opacity-50 absolute inset-0 block;
  }

  .front {
    @apply max-w-screen-2xl mx-auto w-full rounded-b-5xl;
  }

  .header {
    @apply static bg-transparent;
  }

  .open {
    @apply static;
  }

  .inner {
    @apply container px-12 pt-16 pb-32 flex justify-between overflow-auto;
    max-height: calc(100vh - 6rem);

    /*
        make the scrollbar disappear
         */
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      @apply bg-black;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-black;
    }
  }

  .left {
    width: 60%;
  }

  .right {
    width: 32%;
  }

  .secondaries {
    @apply flex mb-24;

    & > * {
      @apply w-1/2 pt-2;
    }
  }

  .ctas {
    @apply w-full pb-8;

    & > * {
      @apply w-1/2;
    }
  }
}

@screen xl {
  .inner {
    @apply px-40;
  }
}

@screen 3xl {
  .front {
    max-width: calc(theme("screens.2xl") + 3.5rem);
  }
}
