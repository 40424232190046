.wrapper {
  @apply container flex flex-col-reverse relative z-10;
  /*@apply mb-8 lg:mb-20;

  &.noGraphic {
    @apply mb-0;
  }

  &.standalone {
    @apply mt-20 lg:mt-40;
  }*/
}

/*.noGraphic {
  .textWrapper {
    @apply mb-0 md:mb-12;
    max-width: 100%;
  }
}*/

.textWrapper {
  @apply mb-0 md:mb-12;
  max-width: 100%;
}

.text {
  @apply relative z-0;
  max-width: 100%;
}

.textTop {
  @apply mt-8;

  p {
    @apply whitespace-pre-wrap;
  }
}

.headline {
  @apply font-semibold col-span-1 hyphens;
}

.subtitle {
}

.imageContainer {
  max-width: 100%;

  .heroInfo {
    @apply lg:flex lg:justify-end gap-12;
    @apply relative;

    @screen 2xl {
      @apply gap-24;
    }
  }

  .heroInfo h6 {
    @apply mb-2;
  }

  .heroInfo p {
    @apply mb-0 text-lg;
  }

  .heroReadTime,
  .heroPublishDate {
    @apply text-center;

    @screen md {
      @apply mt-4;
    }
  }
}

.imageWrapper {
  height: 20rem;
  width: 100%;
  position: relative;
}

.image {
  @apply rounded-4xl lg:rounded-5xl 2xl:rounded-6xl overflow-hidden absolute inset-0;

  & img {
    object-fit: cover;
  }
}

.letterImageV {
  width: 140%;
  position: relative;
  top: -2rem;
  left: 8rem;
  z-index: -1;
  transform: scaleX(-1);
}

.letterImageL {
  width: 60%;
  top: -8rem;
  left: 16.5rem;
  position: relative;
  z-index: -10;
}

@screen sm {
  .text {
    @apply relative z-0;
    max-width: 100%;
  }

  .imageWrapper {
    height: 25.5rem;
    width: 611px;
    position: relative;

    & img {
      @apply rounded-5xl;
      object-fit: cover;
    }
  }

  .letterImageV {
    width: 170%;
    position: relative;
    top: -4rem;
    left: 6.5rem;
    z-index: -1;
    transform: scaleX(-1);
  }
  .letterImageL {
    width: 100%;
    top: -16rem;
    left: 15rem;
    position: relative;
    z-index: -10;
    transform: scaleX(-1) rotate(90deg);
  }
}

@screen md {
  .text {
    @apply relative z-0;
    max-width: 100%;
  }

  .imageContainer {
    @apply w-full;
  }

  .imageWrapper {
    height: 32rem;
    width: 705px;
    position: relative;

    & img {
      @apply rounded-5xl;
      object-fit: cover;
    }
  }
  .letterImageL {
    width: 100%;
    top: -17rem;
    left: 17rem;
    position: relative;
    z-index: -10;
    transform: scaleX(-1) rotate(90deg);
  }
}

@screen lg {
  .wrapper {
    @apply container flex flex-wrap justify-between flex-row w-full;
  }

  .textWrapper {
    max-width: calc(
      theme("container.grid.lg.size") * 6 + theme("container.grid.lg.gap") * 5
    );
  }

  .text {
    @apply relative z-0 text-lg;
  }

  .textTop {
    @apply mt-20;
  }

  .imageContainer {
    width: calc(
      theme("container.grid.lg.size") * 5 + theme("container.grid.lg.gap") * 5
    );
  }
  .imageWrapper {
    height: 32rem;
    width: 698px;
    position: relative;

    & img {
      @apply rounded-5xl;
      object-fit: cover;
    }
  }

  .letterImageV {
    width: 180%;
    position: relative;
    top: -4rem;
    left: -2rem;
    z-index: -1;
    transform: scaleX(-1);
  }

  .letterImageL {
    width: 100%;
    top: -17rem;
    left: 2rem;
    position: relative;
    z-index: -10;
    transform: scaleX(-1) rotate(90deg);
  }
}

@screen xl {
  .text {
    @apply relative z-0 text-xl;
  }

  .textTop {
    @apply mt-14;
    //width: 160%;
  }

  .textWrapper {
    max-width: calc(
      theme("container.grid.xl.size") * 6 + theme("container.grid.xl.gap") * 5
    );
  }

  .imageContainer {
    width: calc(
      theme("container.grid.xl.size") * 5 + theme("container.grid.xl.gap") * 5
    );
  }
  .imageWrapper {
    height: 38rem;
    width: 803px;
    position: relative;

    & img {
      @apply rounded-5xl;
      object-fit: cover;
    }
  }

  .letterImageV {
    left: -4rem;
  }
  .letterImageL {
    width: 100%;
    top: -19rem;
    left: 2rem;
    position: relative;
    z-index: -10;
    transform: scaleX(-1) rotate(90deg);
  }
}

@screen 2xl {
  .text {
    @apply relative z-0 text-2xl;
  }

  .textTop {
    @apply mt-16 mb-28;
  }

  .textWrapper {
    max-width: calc(
      theme("container.grid.2xl.size") * 5 + theme("container.grid.2xl.gap") * 5
    );
  }

  .imageContainer {
    width: calc(
      theme("container.grid.2xl.size") * 6 + theme("container.grid.2xl.gap") * 6
    );
  }

  .imageWrapper {
    height: 38rem;
    width: 803px;
    position: relative;

    & img {
      @apply rounded-5xl;
      object-fit: cover;
    }
  }
}

@screen 3xl {
  .text {
    max-width: calc(
      theme("container.grid.3xl.size") * 6 + theme("container.grid.3xl.gap") * 5
    );
  }
  .textWrapper {
    max-width: calc(
      theme("container.grid.3xl.size") * 7 + theme("container.grid.3xl.gap") * 5
    );
  }

  .textTop {
    @apply my-28;
  }

  .imageContainer {
    width: calc(
      theme("container.grid.3xl.size") * 5 + theme("container.grid.3xl.gap") * 6
    );
  }
  .imageWrapper {
    height: 44rem;
    width: 1059px;
  }

  .letterImageV {
    width: 153%;
    position: relative;
    top: -4rem;
    left: -4rem;
    z-index: -1;
    transform: scaleX(-1);
  }

  .letterImageL {
    width: 90%;
    top: -24rem;
    left: 2rem;
    position: relative;
    z-index: -10;
    transform: scaleX(-1) rotate(90deg);
  }
}
