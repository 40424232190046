.header {
  @apply fixed flex items-start text-black z-50 w-full;

  //transform: translate3d(0, 0, 0);
  //transition: all ease-out 0.4s;

  &:global(.hidden) {
    //transform: translate3d(0, -100%, 0);
  }
}

.logo {
  @apply self-baseline;
  @apply mt-1 ml-4;
  mix-blend-mode: screen;

  :global(.menu-open) & {
    @apply static;
  }

  svg {
    display: inline-block;
    width: auto;
    height: 66px;
  }
}

.nav {
  @apply flex relative bg-black;
  height: 3rem;
  mix-blend-mode: screen;
  margin-left: auto;

  &::before {
    @apply absolute;
    content: "";
    z-index: -1;
    bottom: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 3rem;
    height: 3rem;
    transform: translate3d(-2.2rem, 0, 0) scaleY(-1);
    background-color: theme("colors.black");
    clip-path: url(#letter-path-l-horizontal);
    /*clip-path: polygon(0 70%, 20% 0, 100% 0, 100% 100%, 0 100%);*/
  }
}

.burger {
  @apply bg-black border-0 m-0 cursor-pointer pr-4;
}

@screen lg {
  .nav {
    position: sticky;
    top: 0;
    right: 0;

    height: 4rem;

    &::before {
      width: 4rem;
      height: 4rem;
      transform: translate3d(-3.2rem, 0, 0) scaleY(-1);
    }
  }

  .logo {
    @apply mt-2 ml-4;

    svg {
      width: auto;
      height: 86px;
    }
  }

  .burger {
    @apply hidden;
  }
}
